import React from "react"
import PasswordResetSimple from "views/PasswordResetSimple"
import Place from "../layouts/Place/Place"
const PasswordReset = (): JSX.Element => {
  return (
    <Place>
      <PasswordResetSimple />
    </Place>
  )
}

export default PasswordReset
